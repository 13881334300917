.ant-menu-horizontal span {
    color: #FFF;
    font-weight: 500;
    /* text-shadow: 1px 1px 1px rgba(145, 142, 113, 0.75); */
    /* text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5),
        0px 0px 2px rgba(0, 0, 0, 0.2),
        0px 0px 10px rgba(0, 0, 0, 0.1); */
}

.ant-menu-horizontal span:hover {
    color: #FFF;
    text-shadow:
        1px 1px 2px #333,
        0 0 1em #FFF,
        0 0 1.5em #FFF;
    font-weight: 500;
}

.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
    border: none !important;
}

